import http from "@/http";

import { Threads, Attachment, Page } from "@/types/email.type";

interface GmailResponse {
  mail: {
    content: string;
    attachments: Attachment[];
  };
}

interface SendPayload {
  to: string;
  subject: string;
  body: string;
  attachments: {
    filename: string;
    mimeType: string;
    content: string;
  }[];
}

interface count {
  messageCount: number;
  siceDate: string;
}

interface CountResponse {
  labels:{
    INBOX: count;
  SENT: count;
  SPAM: count;
  TRASH: count;
  ME:count
  UNASSIGNED: count;
  }
}

export interface Label {
  color: string;
  id: string;
  name: string;
  description: string;
  secondaray_color: string;
}

export const gmailApi = () => {
  const connectGmail = async () => {
    return await http.get<{ url: string }>("/inbox/activate-gmail");
  };
  const getMessageList = async (
    nextPageToken: string | null,
    pageQuery?: string
  ) => {
    return await http.get<Page>(
      `/inbox/get-mail-list?pageToken=${nextPageToken}&pageQuery=${pageQuery}`
    );
  };
  const syncMessageList = async () => {
    return await http.get<Page>("/inbox/sync-mail");
  };

  const getMailContent = async (id: string) => {
    return await http.get<GmailResponse>(`/inbox/get-mail-content/${id}`);
  };

  const getThreadsMessages = async (id: string | null) => {
    return await http.get<Threads>(`/inbox/get-threads/${id}`);
  };

  const replayMail = async (message_id: string, body: string) => {
    return await http.post<{ message: string }>(
      `/inbox/replay-mail/${message_id}`,
      {
        message_body: body,
      }
    );
  };

  const sendMail = async (payload: SendPayload) => {
    return await http.post<{ id: string }>("/inbox/send-mail", payload);
  };

  const getLablesCount = async () => {
    return await http.get<CountResponse>("/inbox/get-labels-count");
  };

  const getLables = async () => {
    return await http.get<{ labels: Label[] }>("/inbox/get-labels");
  };

  const addLabel = async (name: string, color: string, description: string) => {
    return await http.post<{ message: string }>("/inbox/add-label", {
      name,
      color,
      description,
    });
  };

  const updateLabel = async (
    id: string,
    name: string,
    color: string,
    description: string
  ) => {
    return await http.put<{ message: string }>(`/inbox/update-label/${id}`, {
      name,
      color,
      description,
    });
  };

  const deleteLabel = async (id: string) => {
    return await http.delete<{ message: string }>(`/inbox/delete-label/${id}`);
  };

  const getUsers = async (threadId: string) => {
    return await http.get<{
      users: {
        name: string;
        email: string;
        userId: string;
        profile_image: string;
        isAssign: boolean;
      }[];
    }>(`/inbox/get-users/${threadId}`);
  };

  const toggleAssignThread = async ({
    assignee_id,
    threadId,
  }: {
    threadId: string;
    assignee_id: string;
  }) => {
    return await http.post<{ message: string, user_id:string,action: string }>(
      `/inbox/toggle-assign-thread/${threadId}`,
      { assignee_id }
    );
  };

  const markThreadStatus = async ({status,threadId}:{threadId: string, status: string}) => {
    return await http.post<{ message: string }>(
      `/inbox/mark-status-as/${threadId}`,{status}
    );
  };

  const getThreadMeta = async (threadId: string) => {
    return await http.get<{ metadata: {
      status: string;
      priority_level: string;
      label: string;
     } }>(
      `/inbox/get-thread-metadata/${threadId}`
    );
  }

  const markThreadPriority = async ({priority_level,threadId}:{threadId: string, priority_level: string}) => {
    return await http.post<{ message: string }>(
      `/inbox/mark-thread-priority/${threadId}`,{priority_level}
    );
  }

    const markThreadLabel = async ({label,threadId}:{threadId: string, label: string}) => {
    return await http.post<{ message: string }>(
      `/inbox/mark-thread-label/${threadId}`,{label}
    );
    }

  return {
    connectGmail,
    getMessageList,
    getMailContent,
    syncMessageList,
    getThreadsMessages,
    replayMail,
    sendMail,
    getLablesCount,
    getLables,
    addLabel,
    updateLabel,
    deleteLabel,
    getUsers,
    toggleAssignThread,
    markThreadStatus,
    getThreadMeta,
    markThreadPriority,
    markThreadLabel
  };
};
