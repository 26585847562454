export const appRoutes = {
  auth: {
    INDEX: "/auth",
    MAIN_LOGIN: "login",
    MAIN_TWO_FA: "verify-2fa",
    INVITE: "invite",
  },
  dashboard: {
    INDEX: "/",
    INBOX: {
      ROOT: "/inbox",
      CATEGORY_PATH: "/inbox/:inbox_type/:mail_id?",


      ALL_MAILS: "/inbox/all-mails",
      MY_MAILS: "/inbox/my-mails",
      UNASSIGNED: "/inbox/unassigned",
      SENT: "/inbox/sent",
      SPAMS: "/inbox/spams",
    },
    ANALYTICS: "/analytics",
    WORKFLOWS: {
      INDEX: "/workflows",
      WORKFLOW_ID: "/workflows/:workflow_id",
      WORKFLOW_GROUND: "/workflows/:workflow_id/ground/:file_id",
    },
    CONFIGURATIONS: "/configurations",
    CONFIG_WORKFLOW: "/configurations/:workflow_id",
    USERS: "/users",
    SETTINGS: {
      INDEX: "/settings",
      PROFILE: "/settings/profile",
      SECURITY: "/settings/security",
      PASSWORD: "/settings/password",
      TEAM: "/settings/team",
      PLAN: "/settings/plan",
      EMAIL: "/settings/email",
    },
  },
};
