import { Button, Spin } from "antd";
import { useLocation, Link } from "react-router-dom";
import { useQuery } from "@tanstack/react-query";

import Text from "@/components/Text";

import { SquarePen, Loader2 } from "lucide-react";

import { Methods, ReplayMessage, useReplay } from "@/providers/replayContext";

import { useUser } from "@/providers/userProvider";

import { inbox_tabs } from "@/utils/links";

import Editor from "./Editor";

import { gmailApi } from "../api/gmail";

const Nav = () => {
  const location = useLocation();
  const { setReplayMessage, replayMessage } = useReplay();
  const { user } = useUser();

  const { getLablesCount } = gmailApi();

  const { data, isLoading } = useQuery({
    queryKey: ["getLablesCount"],
    queryFn: getLablesCount,
    refetchOnWindowFocus: true,
    enabled: !!user?.inbox?.inbox_account_email,
  });

  const calculateTotalCount = () => {
    let totalCount = 0;
    Object.keys(data?.data.labels || {}).forEach((key) => {
      if (key !== "SPAM" && key !== "TRASH" && key !== "ME") {
        totalCount +=
          data?.data.labels[key as keyof typeof data.data.labels]
            ?.messageCount || 0;
      }
    });
    return totalCount;
  };

  return (
    <div className="py-[15px]">
      {user?.inbox?.inbox_account_email && (
        <div className="px-[10px] w-full">
          <Button
            type="dashed"
            size="large"
            className="px-[30px] gap-5"
            onClick={() => {
              setReplayMessage({
                ...replayMessage,
                method: Methods.COMPOSE,
                show: true,
              } as ReplayMessage);
            }}
            icon={
              <SquarePen
                size={22}
                strokeWidth={1.2}
                alignmentBaseline="text-before-edge"
              />
            }
            iconPosition="start"
          >
            Compose
          </Button>
        </div>
      )}
      <div className="mt-[15px] px-[5px]">
        {inbox_tabs.map((tab, index) => (
          <Link to={tab.path} key={index}>
            <div
              className={`flex items-center justify-between px-[15px] py-[10px] mb-1 hover:bg-[#f5f5f5] rounded-[5px] cursor-pointer group ${
                location.pathname === tab.path ||
                location.pathname.includes(tab.path)
                  ? "bg-orange-100/40 !font-bold"
                  : ""
              }`}
            >
              <div className="flex items-center">
                {
                  <div
                    className={`${
                      location.pathname === tab.path
                        ? "fill-orange-200/60"
                        : "fill-none"
                    }`}
                  >
                    {" "}
                    {tab.icon}{" "}
                  </div>
                }
                <Text className="ml-2 text-sm">{tab.name}</Text>
              </div>
              <span className="text-sm text-gray-600">
                {isLoading ? (
                  <Spin
                    size="small"
                    indicator={
                      <Loader2
                        size="8"
                        className="text-gray-400 animate-spin"
                      />
                    }
                  />
                ) : data?.data.labels && tab?.id === "INBOX" ? (
                  calculateTotalCount()
                ) : (
                  data?.data.labels[tab?.id as keyof typeof data.data.labels]
                    ?.messageCount || 0
                )}
              </span>
            </div>
          </Link>
        ))}
      </div>
      {replayMessage?.method === Methods.COMPOSE && replayMessage?.show && (
        <div className="fixed bg-white bottom-0 right-2 w-1/2 border rounded-md z-50">
          <Editor />
        </div>
      )}
    </div>
  );
};

export default Nav;
