import { Avatar, AvatarFallback, AvatarImage } from "@/components/ui/avatar";
import React from "react";
import Highlighter from "react-highlight-words";

import { useUser } from "@/providers/userProvider";

import { genrateInitials } from "@/helper/genrateInitials";

interface ThreadHeaderProps {
  subject: string;
  from: string;
  to: string;
  cc: string;
  threads: number;
  searchText: string;
}

const ThreadHeader: React.FC<ThreadHeaderProps> = ({
  subject,
  cc,
  from,
  threads,
  to,
  searchText,
}) => {
  const { user } = useUser();

  const yourEmail = to?.split("<")[1]?.split(">")[0]?.toLowerCase() || to?.toLowerCase();

  const [destination, setDestination] = React.useState<string | null>(null);

  React.useEffect(() => {
    if (user?.email.toLowerCase() === yourEmail?.toLowerCase()) {
      setDestination(
        `You, ${to
          ?.split(",")
          .map((email) => email)
          ?.slice(1)
          ?.join(",")}`
      );
    } else {
      setDestination(`
        You
      `);
    }
  }, [to, user, yourEmail]);

  return (
    <div className="flex items-start justify-between border-b pb-[10px] sticky top-0 pt-2 z-10 bg-[#fbfbfb]">
      <div className="flex gap-2 items-center">
        <div>
          <Avatar className="h-[50px] w-[50px] text-[20px]">
            <AvatarImage
              src={genrateInitials(
                from?.split("<")[0]?.split(" ")[0].replace(/"/g, "")?.charAt(0)
              ) as string}
            />
            <AvatarFallback>
              <span>
                {from
                  ?.split("<")[0]
                  ?.split(" ")[0]
                  .replace(/"/g, "")
                  ?.charAt(0)?.toLocaleUpperCase()}
              </span>
            </AvatarFallback>
          </Avatar>
        </div>
        <div>
          <h4 className="font-semibold max-w-[700px]">
            <Highlighter
              highlightClassName="bg-orange-300 py-[1px] rounded-md px-[3px]"
              searchWords={[searchText]}
              autoEscape={true}
              textToHighlight={subject}
            />
            <span className="font-normal text-[12px] text-gray-500 ml-2">
              <Highlighter
                highlightClassName="bg-orange-300 py-[1px] rounded-md px-[3px]"
                searchWords={[searchText]}
                autoEscape={true}
                textToHighlight={from}
              />
            </span>
          </h4>
          <div className="flex gap-[20px]">
            <div>
              <span className="text-gray-500 text-[12px]">To: </span>
              <span className="text-[12px] font-semibold">
                <Highlighter
                  highlightClassName="bg-orange-300 py-[1px] rounded-md px-[3px]"
                  searchWords={[searchText]}
                  autoEscape={true}
                  textToHighlight={
                    destination === "You, " ? "You" : destination || to
                  }
                />
              </span>
            </div>
            {cc && (
              <div>
                <span className="text-gray-500 text-[12px]">Cc: </span>
                <span className="text-[12px] font-semibold">
                  <Highlighter
                    highlightClassName="bg-orange-300 py-[1px] rounded-md px-[3px]"
                    searchWords={[searchText]}
                    autoEscape={true}
                    textToHighlight={cc}
                  />
                </span>
              </div>
            )}
          </div>
        </div>
      </div>
      <div>
        <p className="text-gray-500 text-sm">{threads} threads</p>
      </div>
    </div>
  );
};

export default ThreadHeader;
