import { Link, useLocation, useNavigate } from "react-router-dom";
import * as Sentry from "@sentry/react";
import {
  MoreVertical,
  ExternalLink,
  MessageCircleQuestion,
  Bug,
} from "lucide-react";
import React from "react";
import type { MenuProps } from "antd";
import { Menu, Button } from "antd";

import {
  Users,
  Bolt,
  Workflow,
  BarChartBig,
  Settings2,
  Inbox,
} from "lucide-react";
import { RiMenuFold2Line, RiMenuUnfold4Line2 } from "react-icons/ri";

import useLogout from "@/hooks/useLogout";
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuLabel,
  DropdownMenuSeparator,
  DropdownMenuTrigger,
} from "../ui/dropdown-menu";
import { Avatar, AvatarFallback, AvatarImage } from "../ui/avatar";
// import Text from "../Text";

import { useUser } from "@/providers/userProvider";

import { appRoutes } from "@/utils/paths";

import { RoleEnum } from "@/types/role";
import RequestForm from "../RequestForm/RequestForm";

// import opticalAiIcon from "@/assets/opticalai.png";
import  WinggLogo from "@/assets/dashboard-logo.png"
import  TextLogo from "@/assets/Wingg-Final.png"
import storage from "@/utils/storage";
import {NAV_COLLAPSED} from '@/constant'

type MenuItem = Required<MenuProps>["items"][number];

const Nav = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const { user } = useUser();
  const logout = useLogout();
  const [requestFormOpen, setRequestFormOpen] = React.useState(false);

  const handleFeedback = async () => {
    Sentry.setUser({
      email: user?.email,
      name: user?.name,
      id: user?.userId,
    });
    const form = await Sentry.feedbackAsyncIntegration({
      colorScheme: "light",
      themeLight: {
        submitBackground: "rgba(22,113,242,1)",
        submitBackgroundHover: "rgba(22,113,242,0.8)",
        submitBorder: "rgba(22,113,242,1)",
        submitOutlineFocus: "rgba(22,113,242,1)",
        submitForeground: "rgba(255,255,255,1)",
        submitForegroundHover: "rgba(255,255,255,1)",
      },
      isNameRequired: true,
      showBranding: false,
      showName: true,
      showEmail: true,
      useSentryUser: {
        email: "email",
        name: "name",
        id: "id",
      },
      submitButtonLabel: "Submit",
      formTitle: "Report an issue",
      successMessageText: "Thank you for your time!",
      errorMessageText: "Something went wrong. Please try again.",
    }).createForm();
    form.appendToDom();
    form.open();
  };

  const handleOpenRequest = () => {
    setRequestFormOpen(true);
  };

  const menuItems: MenuItem[] = [
    {
      label: "Inbox",
      icon: <Inbox size={22} strokeWidth={1.4} className="dark:text-gray-50" />,
      key: appRoutes.dashboard.INBOX.ROOT,
      onClick: () => navigate(appRoutes.dashboard.INBOX.ROOT),
      className: "text-sm font-normal pt-1 ",
    },
    {
      label: "Workflows",
      icon: (
        <Workflow size={22} strokeWidth={1.4} className="dark:text-gray-50" />
      ),
      key: appRoutes.dashboard.WORKFLOWS.INDEX,
      className: "text-sm font-normal pt-1",
      onClick: () => navigate(appRoutes.dashboard.WORKFLOWS.INDEX),
    },
    {
      label: "Analytics",
      icon: (
        <BarChartBig
          size={22}
          strokeWidth={1.4}
          className="dark:text-gray-50"
        />
      ),
      key: appRoutes.dashboard.ANALYTICS,
      className: "text-sm font-normal pt-1",
      onClick: () => navigate(appRoutes.dashboard.ANALYTICS),
    },
    {
      label: "Configurations",
      icon: (
        <Settings2 size={22} strokeWidth={1.4} className="dark:text-gray-50" />
      ),
      key: appRoutes.dashboard.CONFIGURATIONS,
      rootClassName: "text-sm font-normal pt-1",
      className: "text-sm font-normal",
      onClick: () => navigate(appRoutes.dashboard.CONFIGURATIONS),
      children: [
        {
          label: (() => {
            return (
              <Link key={"inbounds"} to={`${location.pathname}#inbounds`}>
                Inbounds
              </Link>
            );
          })(),
          className: "text-sm font-normal",
          key: "#inbounds",
        },
        {
          label: (() => {
            return (
              <Link key={"schema"} to={`${location.pathname}#schema`}>
                Schema
              </Link>
            );
          })(),
          className: "text-sm font-normal",
          key: "#schema",
        },
        {
          label: (() => {
            return (
              <Link key={"AI-Engine"} to={`${location.pathname}#ai-engine`}>
                AI Engine
              </Link>
            );
          })(),
          className: "text-sm font-normal",
          key: "AI-Engine",
        },
        {
          label: (() => {
            return (
              <Link
                key={"GL-Coding-Rules"}
                to={`${location.pathname}#gl-coding-rules`}
              >
                GL Coding Rules
              </Link>
            );
          })(),
          className: "text-sm font-normal",
          key: "GL-Coding-Rules",
        },
        {
          label: (() => {
            return (
              <Link key={"Approvals"} to={`${location.pathname}#approvals`}>
                Approvals
              </Link>
            );
          })(),
          className: "text-sm font-normal",
          key: "Approvals",
        },
        {
          label: (() => {
            return (
              <Link key={"Export"} to={`${location.pathname}#export`}>
                Export
              </Link>
            );
          })(),
          className: "text-sm font-normal",
          key: "Export",
        },
      ],
    },
    {
      label: "Users & Permissions",
      className: "text-sm font-normal pt-1",
      icon: <Users size={22} strokeWidth={1.4} />,
      key: appRoutes.dashboard.USERS,
      onClick: () => navigate(appRoutes.dashboard.USERS),
    },
    {
      label: "Account Settings",
      className: "text-sm font-normal pt-1",
      icon: <Bolt size={22} strokeWidth={1.4} />,
      key: appRoutes.dashboard.SETTINGS.INDEX,
      onClick: () => navigate(appRoutes.dashboard.SETTINGS.INDEX),
    },
  ];

  const lowerMenuItems: MenuItem[] = [
    {
      label: "Report an issue",
      icon: <Bug strokeWidth={1.2} size={20} className="dark:text-gray-50" />,
      onClick: handleFeedback,
      key: "report_issue",
      className: "text-sm font-normal pt-1",
    },
    {
      label: "Support request",
      icon: (
        <MessageCircleQuestion
          strokeWidth={1.2}
          size={20}
          className="dark:text-gray-50"
        />
      ),
      className: "text-sm font-normal pt-1",
      onClick: handleOpenRequest,
      key: "support_request",
    },
  ];

  const filteredItems = menuItems.filter((item) => {
    if (!item || !user) return false;
    if (
      item.key === appRoutes.dashboard.USERS &&
      user.role !== RoleEnum.Admin &&
      user.role !== RoleEnum.Manager
    )
      return false;
    return true;
  });

  const selectedKeys = filteredItems
    .filter((item) => {
      const itemKey = item?.key?.toString().toLocaleLowerCase() as string;
      const pathMatch = location.pathname.includes(itemKey);
      const hashMatch =
        location.hash.toLowerCase() && location.hash.includes(itemKey);
      return pathMatch || hashMatch;
    })
    .map((item) => item?.key);

  const [collapsed, setCollapsed] = React.useState(true);

  const toggleCollapsed = () => {
    setCollapsed(!collapsed);
    storage.set(NAV_COLLAPSED, collapsed);
  };

  React.useEffect(() => {
    const getPreviousState = storage.get(NAV_COLLAPSED);
    if (getPreviousState) {
      setCollapsed(getPreviousState === "true");
    }
  }, [collapsed]);

  return (
    <React.Fragment>
      <RequestForm
        open={requestFormOpen}
        onChange={() => setRequestFormOpen(false)}
      />
      <div
        className={
          collapsed
            ? "font-popins bg-[#f9f4f1] text-[#7c7c7c] flex flex-col items-start justify-between mt-5 h-[calc(100vh-20px)]"
            : "font-popins bg-[#f9f4f1] text-[#7c7c7c] flex flex-col items-start justify-between mt-5 h-[calc(100vh-20px)] w-[260px]"
        }
      >
        <div
          className={
            collapsed
              ? "flex flex-col gap-2 items-center justify-center "
              : "flex flex-col gap-2 w-[225px]"
          }
        >
          <Link
            to={appRoutes.dashboard.WORKFLOWS.INDEX}
            className="text-xl font-semibold tracking-tight flex items-end"
          >
            {collapsed ? (
              <img src={WinggLogo} className="w-[55px] h-[55px]" />
            ) : (
              <div className="flex items-start my-[0px] mb-[15px] transition delay-300">
                <img src={TextLogo} className="w-[180px] h-[80px] mb-[-20px] ml-5"/>
              </div>
            )}
          </Link>
          <Menu
            mode="inline"
            selectedKeys={selectedKeys as string[]}
            items={filteredItems}
            theme="light"
            className="!border-none px-2"
            style={{ background: "#f9f4f1" }}
            inlineCollapsed={collapsed}
          />
        </div>

        <div
          className={
            collapsed
              ? "flex flex-col items-center"
              : "flex flex-col items-center"
          }
        >
          <div className={collapsed ? "flex flex-col items-start" : ""}>
            <Menu
              mode="inline"
              items={lowerMenuItems}
              theme="light"
              className="!border-none px-2 pt-1"
              style={{ background: "#f9f4f1" }}
              inlineCollapsed={collapsed}
            />
            <Button
              type="dashed"
              className={collapsed ? "w-[50px] ml-5" : "ml-6 w-[160px]"}
              onClick={toggleCollapsed}
              style={{ marginBottom: 15, marginTop: 5 }}
            >
              {!collapsed ? <RiMenuUnfold4Line2 /> : <RiMenuFold2Line />}
            </Button>
          </div>
          <DropdownMenu>
            <DropdownMenuTrigger>
              <div
                className={
                  !collapsed
                    ? "flex gap-4 py-2.5 px-2 group border-t  min-w-[calc(100%+22px)] w-[205px] bg-gray-100 justify-between items-center"
                    : "flex mb-2 group justify-center items-center"
                }
              >
                <Avatar className="ml-1 ring-1 group-hover:!bg-blue-500">
                  <AvatarImage
                    className="object-center object-cover"
                    src={user?.profile_image}
                  />
                  <AvatarFallback>
                    {user &&
                      user?.name?.charAt(0)?.toLocaleUpperCase() +
                        user?.name?.split(" ")[1]?.charAt(0)?.toLocaleUpperCase()}
                  </AvatarFallback>
                </Avatar>
                {!collapsed && (
                  <div className="flex items-center justify-between text-sm gap-2 w-full">
                    <p className="font-popins dark:text-white overflow-hidden text-ellipsis text-left whitespace-nowrap">
                      {user && user?.name}
                    </p>
                    <MoreVertical size={20} strokeWidth={1.2} />
                  </div>
                )}
              </div>
            </DropdownMenuTrigger>
            <DropdownMenuContent className="max-w-auto ml-[8px] shadow-md">
              <DropdownMenuLabel className="flex gap-[8px]">
                <div className="flex flex-col">
                  <p className="font-normal text-[15px]">
                    <span className="dark:text-gray-50 text-gray-600 capitalize">
                      {user && user.role}
                    </span>
                  </p>
                  <p className="font-normal text-[12px] text-gray-500 dark:text-gray-300">
                    {user && user.email}
                  </p>
                </div>
              </DropdownMenuLabel>
              <DropdownMenuSeparator />
              <Link
                to={
                  import.meta.env.VITE_DOCUMENRATION_URL ||
                  "https://docs.getopticalai.com/"
                }
                target="_blank"
                rel="noopener noreferrer"
              >
                <DropdownMenuItem className="flex items-center gap-[5px]">
                  Documentation <ExternalLink size={15} strokeWidth={1.2} />
                </DropdownMenuItem>
              </Link>
              <DropdownMenuItem className="flex items-center gap-[5px]">
                Join community <ExternalLink size={15} strokeWidth={1.2} />
              </DropdownMenuItem>
              <DropdownMenuSeparator />
              <DropdownMenuItem onClick={logout}>Logout</DropdownMenuItem>
            </DropdownMenuContent>
          </DropdownMenu>
        </div>
      </div>
    </React.Fragment>
  );
};

export default Nav;
