import React from "react";

import { Page } from "@/types/email.type";

interface PageProps {
  page: Page;
  setPage: React.Dispatch<React.SetStateAction<Page>>;
}

interface PageProviderProps {
  children: React.ReactNode;
}

const MyMailContext = React.createContext<PageProps | undefined>(undefined);

export const MyMailProvider: React.FC<PageProviderProps> = ({ children }) => {
  const [page, setPage] = React.useState<Page>({
    messages: {
      messages: [],
      nextPageToken: null,
    },
    lastSyncDate: null,
  });

  return (
    <MyMailContext.Provider value={{ page, setPage }}>
      {children}
    </MyMailContext.Provider>
  );
};

// eslint-disable-next-line react-refresh/only-export-components
export const useMyMail = () => {
  const context = React.useContext(MyMailContext);
  if (!context) {
    throw new Error("useMyMail must be used within a MyMailProvider");
  }
  return context;
};
