import React from "react";
import { Outlet, Navigate , useLocation} from "react-router-dom";

import Plain from "@/components/Plain";
import AppLoader from "@/components/Apploader";
import Nav from "@/components/Navbars/Nav";
// import ReleaseNote from "@/components/ReleaseNote";

import { useToken } from "@/providers/useAuthProvider";
import { useUser } from "@/providers/userProvider";

import useHead from "@/hooks/useHead";
import useWebSocket from "@/hooks/useWebsocket";

import useGetUser from "@/hooks/useGetUser";

import { appRoutes } from "@/utils/paths";
import storage from "@/utils/storage";
const Dashboard = () => {
  useHead({
    title: "Dashboard | OpticalAI",
    description: "Dashboard for OpticalAI",
  });

  const {pathname} = useLocation();
  const { getUser } = useGetUser();
  const { user } = useUser();
  const token = useToken();

  const { isConnected, subscribe } = useWebSocket();

  React.useEffect(() => {
    if (isConnected && user) {
      subscribe(user.userId);
    }
  }, [user, isConnected, subscribe]);

  React.useEffect(() => {
    if (token.access_token) {
      getUser();
    }
  }, [token.access_token]);

  if (!Object.keys(token ?? {}).length) {
    storage.set("redirect", pathname);
    return <Navigate to={appRoutes.auth.INDEX} replace/>;
  }

  return (
    <AppLoader userLoading={!user}>
      {/* <ReleaseNote /> */}
      <Plain className="flex h-screen fixed top-0 left-0 right-0 bottom-0 bg-[#f9f4f1] dark:bg-background">
        <Plain className="overflow-y-scroll overflow-x-hidden">
          <Nav />
        </Plain>
        <Plain className="bg-white w-full mt-3 rounded-xl rounded-tr-none rounded-br-none rounded-bl-none border-solid border-0 border-t border-l overflow-scroll dark:bg-background">
          <Outlet />
        </Plain>
      </Plain>
    </AppLoader>
  );
};

export default Dashboard;
