import React from "react";
import { useParams } from "react-router-dom";
import { useQuery } from "@tanstack/react-query";

import Threads from "../components/Threads";
import List from "../components/List";

import useHead from "@/hooks/useHead";

import { gmailApi } from "../api/gmail";

const AllMails = () => {
  const { inbox_type } = useParams();
  const {
    getLables,
  } = gmailApi();

  useHead({
    title: "Mailbox | " + inbox_type,
    description: "All your emails in one place",
  });

  const { data: labels } = useQuery({
    queryKey: ["threadLabels"],
    queryFn: () => getLables(),
  });

  const [globalSearch, setGlobalSearch] = React.useState("");

  return (
    <div className="flex items-start">
      <List setGlobalSearch={setGlobalSearch} labels={labels?.data.labels}/>
      <div className="w-full bg-[#fbfbfb] flex items-center justify-center">
        <Threads searchText={globalSearch} labels={labels?.data.labels}/>
      </div>
    </div>
  );
};

export default AllMails;
