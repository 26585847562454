import React from "react";
import {
  Dropdown,
  Modal,
  Input,
  Divider,
  Avatar,
  Tooltip,
  Button,
  message,
  Skeleton,
} from "antd";
import type { MenuProps } from "antd";
import { useQuery, useMutation, useQueryClient } from "@tanstack/react-query";
import { AxiosError } from "axios";

import { useUser } from "@/providers/userProvider";

import {
  ChevronDownIcon,
  LucideUnlock,
  LucideLock,
  LucideClock,
  Check,
  UserPlus,
} from "lucide-react";

import { gmailApi, Label } from "../../api/gmail";

import { useThread } from "@/providers/thredContext";
import { useMyMail } from "@/providers/myMailContext";

const Header = ({ labels }: { labels: Label[] | undefined }) => {
  const { thread } = useThread();
  const { setPage } = useMyMail();
  const queryClient = useQueryClient();

  const statusList: MenuProps["items"] = [
    {
      key: "1",
      label: "Open",
      icon: <LucideUnlock size={16} strokeWidth={1.5} />,
      onClick: () => {
        markStatus({
          threadId: thread.thredId ?? "",
          status: "OPEN",
        });
      },
    },
    {
      key: "2",
      label: "Closed",
      icon: <LucideLock size={16} strokeWidth={1.5} />,
      onClick: () => {
        markStatus({
          threadId: thread.thredId!,
          status: "CLOSED",
        });
      },
    },
    {
      key: "3",
      label: "Pending",
      icon: <LucideClock size={16} strokeWidth={1.5} />,
      onClick: () => {
        markStatus({
          threadId: thread.thredId!,
          status: "PENDING",
        });
      },
    },
    {
      key: "4",
      label: "Resolved",
      icon: <Check size={16} strokeWidth={1.5} color="green" />,
      onClick: () => {
        markStatus({
          threadId: thread.thredId!,
          status: "RESOLVED",
        });
      },
    },
  ];

  const priorityList: MenuProps["items"] = [
    {
      key: "1",
      label: (
        <div className="flex items-center justify-start gap-[5px]">
          <span className="font-semibold text-red-500">P1</span>
          <p className="text-sm text-gray-700"> High</p>
        </div>
      ),
      onClick: () => {
        MarkThreadPriority({
          threadId: thread.thredId!,
          priority_level: "P1 High",
        });
      },
    },
    {
      key: "2",
      label: (
        <div className="flex items-center justify-start gap-[5px]">
          <span className="font-semibold text-yellow-500">P2</span>
          <p className="text-sm text-gray-700"> Medium</p>
        </div>
      ),
      onClick: () => {
        MarkThreadPriority({
          threadId: thread.thredId!,
          priority_level: "P2 Medium",
        });
      },
    },
    {
      key: "3",
      label: (
        <div className="flex items-center justify-start gap-[5px]">
          <span className="font-semibold text-blue-500">P3</span>
          <p className="text-sm text-gray-700"> Low</p>
        </div>
      ),
      onClick: () => {
        MarkThreadPriority({
          threadId: thread.thredId!,
          priority_level: "P3 Low",
        });
      },
    },
  ];

  const [labelList, setLabelList] = React.useState<MenuProps["items"]>([]);
  const [isModalOpen, setIsModalOpen] = React.useState(false);
  const { user } = useUser();
  const {
    getUsers,
    toggleAssignThread,
    markThreadStatus,
    getThreadMeta,
    markThreadPriority,
    markThreadLabel,
  } = gmailApi();

  const showModal = () => {
    setIsModalOpen(true);
  };

  const handleOk = () => {
    setIsModalOpen(false);
  };

  const handleCancel = () => {
    setIsModalOpen(false);
  };

  const [userList, setUserList] = React.useState<
    {
      name: string;
      email: string;
      userId: string;
      profile_image: string;
      isAssign: boolean;
    }[]
  >([]);

  const { data, refetch } = useQuery({
    queryKey: ["inbox-users", thread.thredId!],
    queryFn: () => getUsers(thread.thredId!),
    enabled: !!user?.inbox?.inbox_account_email,
  });

  React.useEffect(() => {
    if (labels) {
      setLabelList(
        labels?.map((label) => ({
          key: label.id,
          label: (
            <div className="flex items-center justify-start gap-[10px]">
              <div
                className={`h-[12px] w-[12px] rounded-md border-2`}
                style={{
                  borderColor: label.color,
                }}
              />
              <p className="text-sm text-gray-700 capitalize">
                {label.name?.toLowerCase()}
              </p>
            </div>
          ),
          onClick: () => {
            markThreadLabelMutate({
              threadId: thread.thredId!,
              label: label.name,
            });
          },
        }))
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [labels, thread.thredId]);

  const { mutate: markStatus } = useMutation({
    mutationKey: ["markThreadStatus"],
    mutationFn: markThreadStatus,
    onMutate: () => {
      const hide = message.loading("Updating status");
      return { hide };
    },
    onError: (error: AxiosError) => {
      message.error((error.response?.data as { message: string }).message);
    },
    onSuccess: (data, _, ctx) => {
      ctx.hide();
      message.success(data.data.message);
      refetchMetaData();
    },
  });

  React.useEffect(() => {
    if (data) {
      setUserList(
        data.data.users as {
          name: string;
          email: string;
          userId: string;
          profile_image: string;
          isAssign: boolean;
        }[]
      );
    }
  }, [data]);

  const { mutate, isPending } = useMutation({
    mutationKey: ["assign-thread"],
    mutationFn: toggleAssignThread,
    onSuccess: (data) => {
      message.success(data.data.message);
      queryClient.invalidateQueries({
        queryKey: ["getAllMails", "ME"],
      });
      queryClient.invalidateQueries({
        queryKey: ["getLablesCount"],
      });
      refetch();
      const user = userList.filter(
        (usr) => usr.userId === data.data.user_id
      )[0];
      setPage((prev) => ({
        ...prev,
        messages: {
          ...prev.messages,
          messages: prev.messages.messages.map((message) => {
            if (message.threadId === thread.thredId) {
              let updatedAssignees = message.assignees || [];
              if (data.data.action === "ADD") {
                if (
                  !updatedAssignees.some(
                    (assingnee) => assingnee?.userId === data.data?.user_id
                  )
                ) {
                  updatedAssignees = [...updatedAssignees, user];
                }
              }
              if (data.data.action === "REMOVE") {
                updatedAssignees = updatedAssignees.filter(
                  (assingnee) => assingnee?.userId !== data.data?.user_id
                );
              }
              return {
                ...message,
                assignees: updatedAssignees,
              };
            }
            return message;
          }),
        },
      }));
    },
    onError: (error: AxiosError) => {
      message.error((error.response?.data as { message: string }).message);
    },
  });

  const {
    data: threadMetaData,
    status,
    refetch: refetchMetaData,
  } = useQuery({
    queryKey: ["thread-meta", thread.thredId!],
    queryFn: () => getThreadMeta(thread.thredId!),
  });

  const { mutate: MarkThreadPriority } = useMutation({
    mutationKey: ["markThreadPriority"],
    mutationFn: markThreadPriority,
    onMutate: () => {
      const hide = message.loading("Updating priority level");
      return { hide };
    },
    onSuccess: (data, _, context) => {
      context?.hide();
      message.success(data.data.message);
      refetchMetaData();
    },
    onError: (error: AxiosError) => {
      message.error((error.response?.data as { message: string }).message);
    },
  });

  const { mutate: markThreadLabelMutate } = useMutation({
    mutationKey: ["markThreadLabel"],
    mutationFn: markThreadLabel,
    onMutate: () => {
      const hide = message.loading("Updating label");
      return { hide };
    },
    onError: (error: AxiosError) => {
      message.error((error.response?.data as { message: string }).message);
    },
    onSuccess: (data, _, ctx) => {
      ctx.hide();
      message.success(data.data.message);
      refetchMetaData();
    },
  });

  return (
    <div className="flex items-center w-full justify-between mb-3 gap-[20px]">
      <div className="flex gap-[10px] items-center justify-start">
        <div className="flex items-center gap-[5px] justify-start">
          <Avatar.Group
            max={{
              count: 2,
              popover: { title: "More members" },
            }}
          >
            {data?.data.users
              .filter((usr) => usr.isAssign)
              .map((usr) => (
                <Tooltip
                  title={
                    <>
                      <p>{usr.name}</p>
                      <p>{usr.email}</p>
                    </>
                  }
                >
                  <Avatar
                    key={usr.userId}
                    src={usr.profile_image}
                    alt={usr.name}
                    className="rounded-xl"
                  />
                </Tooltip>
              ))}
          </Avatar.Group>
          <Tooltip title="handle assignment">
            <UserPlus
              onClick={showModal}
              size={30}
              strokeWidth={1.5}
              className="text-gray-500 cursor-pointer hover:text-gray-700 hover:scale-110 bg-gray-200/80 p-[4px] rounded-xl"
            />
          </Tooltip>
        </div>
      </div>
      {status === "pending" && <Skeleton className="w-full h-[20px]" />}
      {status === "success" && (
        <div className="flex items-center justify-center gap-[10px]">
          <Dropdown
            arrow
            menu={{ items: statusList }}
            className={
              threadMetaData.data.metadata.status === "RESOLVED"
                ? "border-green-500 ml-4 flex items-center justify-center"
                : "ml-4 flex items-center justify-center"
            }
          >
            <div className="flex items-center gap-[8px] border rounded-full px-[5px] py-[3px] bg-white">
              {(() => {
                const { status } = threadMetaData.data.metadata;
                switch (status) {
                  case "RESOLVED":
                    return <Check size={16} strokeWidth={1.5} color="green" />;
                  case "OPEN":
                    return <LucideUnlock size={16} strokeWidth={1.5} />;
                  case "CLOSED":
                    return <LucideLock size={16} strokeWidth={1.5} />;
                  case "PENDING":
                    return <LucideClock size={16} strokeWidth={1.5} />;
                  default:
                    return null;
                }
              })()}
              <p
                className={
                  threadMetaData.data.metadata.status === "RESOLVED"
                    ? "text-green-700 text-sm capitalize font-semibold"
                    : "text-sm text-gray-600 capitalize font-semibold"
                }
              >
                {!threadMetaData?.data.metadata.status
                  ? "mark as"
                  : threadMetaData?.data.metadata.status.toLowerCase()}
              </p>
              <ChevronDownIcon size={16} strokeWidth={1.2} />
            </div>
          </Dropdown>

          <Dropdown arrow menu={{ items: priorityList }}>
            <div className="flex items-center gap-[8px] border rounded-full px-[5px] py-[3px] bg-white">
              <span className="bg-gray-200 rounded-full px-[6px] text-sm">
                {threadMetaData.data.metadata.priority_level?.split(" ")[0] ||
                  "P3"}
              </span>
              <p
                className={
                  threadMetaData.data.metadata.priority_level === "P1 High"
                    ? "text-sm text-red-600"
                    : threadMetaData.data.metadata.priority_level ===
                      "P2 Medium"
                    ? "text-sm text-yellow-600"
                    : "text-sm text-blue-600"
                }
              >
                {threadMetaData.data.metadata.priority_level?.split(" ")[1] ||
                  "Low"}
              </p>
              <ChevronDownIcon size={16} strokeWidth={1.2} />
            </div>
          </Dropdown>

          <Dropdown arrow menu={{ items: labelList }}>
            <div
              className="flex items-center gap-[8px] border rounded-full px-[10px] py-[3px]"
              style={{
                background: threadMetaData.data.metadata.label
                  ? labels?.find(
                      (label) =>
                        label.name === threadMetaData.data.metadata.label
                    )?.secondaray_color ?? "#e0e0e0"
                  : "#e0e0e0",
                borderColor: threadMetaData.data.metadata.label
                  ? labels?.find(
                      (label) =>
                        label.name === threadMetaData.data.metadata.label
                    )?.secondaray_color
                  : "#e0e0e0",
              }}
            >
              <div className="flex items-center justify-center gap-[5px]">
                <div
                  className={`h-[12px] w-[12px] rounded-md border-2`}
                  style={{
                    borderColor: threadMetaData.data.metadata.label
                      ? labels?.find(
                          (label) =>
                            label.name === threadMetaData.data.metadata.label
                        )?.color
                      : "transparent",
                  }}
                />
                <p className="text-sm text-gray-700 capitalize">
                  {threadMetaData.data.metadata.label?.toLowerCase() ||
                    "Not set"}
                </p>
              </div>
              <ChevronDownIcon size={16} strokeWidth={1.2} />
            </div>
          </Dropdown>
        </div>
      )}
      <Modal
        title="Assign to a team member"
        open={isModalOpen}
        onOk={handleOk}
        okButtonProps={{ style: { display: "none" } }}
        cancelButtonProps={{ style: { display: "none" } }}
        onCancel={handleCancel}
      >
        <Input.Search
          placeholder="search by name"
          onChange={(e) => {
            if (e.target.value) {
              setUserList(
                userList.filter((usr) =>
                  usr.name.toLowerCase().includes(e.target.value.toLowerCase())
                )
              );
            } else {
              setUserList(
                data?.data.users as {
                  name: string;
                  email: string;
                  userId: string;
                  profile_image: string;
                  isAssign: boolean;
                }[]
              );
            }
          }}
          classNames={{
            input:
              "hover:!border-[#fa6e3c] active:!border-[#fa6e3c] focus-within:!border-[#fa6e3c] focus:!border-[#fa6e3c] focus:!ring-[#fa6e3c] focus:!ring-opacity-50",
          }}
          className="mt-2"
        />
        <Divider />
        <div className="max-h-[400px] overflow-scroll">
          {userList.map((usr) => (
            <div className="w-full flex items-center justify-between gap-[10px] border py-[5px] px-[10px] rounded-md mb-[10px]">
              <div className="flex gap-[10px] items-center ">
                <img
                  src={usr?.profile_image}
                  className="rounded-full h-[50px] w-[50px] object-cover object-top"
                />
                <div>
                  <p className="font-semibold">
                    {user?.email === usr?.email ? "You" : usr?.name}
                  </p>
                  <p className="text-sm text-gray-600">{usr.email}</p>
                </div>

                <style>{`
            .ant-input-group-addon:hover {
              color: #fa6e3c; /* Change parent color */
              }
              
              .ant-input-group-addon:hover .anticon-search {
                color: #fa6e3c; /* Change child color on parent hover */
                }
                `}</style>
              </div>
              <Button
                loading={isPending}
                onClick={() =>
                  mutate({
                    assignee_id: usr.userId,
                    threadId: thread.thredId!,
                  })
                }
              >
                {!usr.isAssign ? "Assign" : "Unassign"}
              </Button>
            </div>
          ))}
        </div>
        {userList.length === 0 && (
          <p className="text-center text-gray-500">No user found</p>
        )}
      </Modal>
    </div>
  );
};

export default Header;
