import {
  Modal,
  Input,
  ColorPicker,
  theme,
  Row,
  Col,
  Divider,
  message,
  Button,
  Dropdown,
  Popconfirm,
} from "antd";
import type { ColorPickerProps, MenuProps, PopconfirmProps } from "antd";
import { cyan, generate, green, presetPalettes, red } from "@ant-design/colors";
import React from "react";
import { Plus, MoreHorizontal, Trash2, PenIcon } from "lucide-react";
import { useQuery, useMutation, useQueryClient } from "@tanstack/react-query";
import { Loader2 } from "lucide-react";
import { AxiosError } from "axios";

import { gmailApi } from "../api/gmail";

import { useUser } from "@/providers/userProvider";

import Text from "@/components/Text";

const Labels = () => {
  const { getLables, addLabel, updateLabel, deleteLabel } = gmailApi();
  const { user } = useUser();
  const queryClient = useQueryClient();

  const [openDropdownIndex, setOpenDropdownIndex] = React.useState<
    number | null
  >(null);
  const [deleteState, setDeleteState] = React.useState<{
    [key: number]: boolean;
  }>({});
  const [isModalVisible, setIsModalVisible] = React.useState(false);
  const [isEditabel, setIsEditabel] = React.useState(false);
  const [color, setColor] = React.useState("");
  const [response, setResponse] = React.useState<{
    id: string;
    name: string;
    description: string;
  }>({ id: "", description: "", name: "" });

  const { data, status, refetch } = useQuery({
    queryKey: ["getLables"],
    queryFn: getLables,
    enabled: !!user?.inbox?.inbox_account_email,
  });

  const { mutate: addLabelMutate, isPending } = useMutation({
    mutationKey: ["createLabel"],
    mutationFn: () => addLabel(response.name, color, response.description),
    onSuccess: (data) => {
      message.success(data.data.message);
      queryClient.invalidateQueries({
        queryKey: ["threadLabels"],
      });
      refetch();
      setIsModalVisible(false);
      setResponse({ id: "", description: "", name: "" });
      setColor("");
    },
    onError: (error: AxiosError) => {
      message.error((error.response?.data as { message: string }).message);
    },
  });

  const { mutate: updateLabelMutate, isPending: updatePending } = useMutation({
    mutationKey: ["updateLabel"],
    mutationFn: () =>
      updateLabel(response.id, response.name, color, response.description),
    onSuccess: (data) => {
      message.success(data.data.message);
      queryClient.invalidateQueries({
        queryKey: ["threadLabels"],
      });
      refetch();
      setIsModalVisible(false);
      setResponse({ id: "", description: "", name: "" });
      setColor("");
    },
    onError: (error: AxiosError) => {
      message.error((error.response?.data as { message: string }).message);
    },
  });

  const { mutate: deleteLabelMutate, isPending: deletePending } = useMutation({
    mutationKey: ["deleteLabel"],
    mutationFn: () => deleteLabel(response.id),
    onMutate: () => {
      const hide = message.loading("Deleting label...");
      return { hide };
    },
    onSuccess: (data, _, ctx) => {
      ctx?.hide();
      message.success(data.data.message);
      queryClient.invalidateQueries({
        queryKey: ["threadLabels"],
      });
      refetch();
      setIsModalVisible(false);
      setResponse({ id: "", description: "", name: "" });
      setColor("");
    },
    onError: (error: AxiosError) => {
      message.error((error.response?.data as { message: string }).message);
    },
  });

  const handleCretaeLabel = () => {
    if (!response.name) {
      message.error("Please enter a label name.");
      return;
    }
    if (!color) {
      message.error("Please select a color.");
      return;
    }
    if (!response.description) {
      message.error("Please enter a description for the label.");
      return;
    }
    if (data?.data.labels.find((label) => label.name === response.name)) {
      message.error("Label name already exists.");
      return;
    }
    addLabelMutate();
  };

  const handleUpdateLabel = () => {
    if (response.name === data?.data.labels[openDropdownIndex ?? 0].name) {
      message.error("Please enter a new label name.");
      return;
    }
    if (!response.name) {
      message.error("Please enter a label name.");
      return;
    }
    if (!color) {
      message.error("Please select a color.");
      return;
    }
    if (!response.description) {
      message.error("Please enter a description for the label.");
      return;
    }
    updateLabelMutate();
  };

  const confirm: PopconfirmProps["onConfirm"] = () => {
    deleteLabelMutate();
    setDeleteState((prev) => ({ ...prev, [openDropdownIndex!]: false }));
    setOpenDropdownIndex(null);
  };

  const cancel: PopconfirmProps["onCancel"] = () => {
    setIsModalVisible(false);
    setOpenDropdownIndex(null);
  };

  const items: MenuProps["items"] = [
    {
      key: "1",
      label: <p>Edit</p>,
      icon: <PenIcon size={16} strokeWidth={1.2} />,
      onClick: () => {
        setIsModalVisible(true);
        setIsEditabel(true);
        setResponse(
          data?.data.labels[openDropdownIndex!] ?? {
            id: "",
            description: "",
            name: "",
          }
        );
        setColor(data?.data.labels[openDropdownIndex!].color ?? "");
        setOpenDropdownIndex(null);
      },
    },
    {
      key: "2",
      danger: true,
      label: (
        <Popconfirm
          title="Delete the label"
          description="Are you sure to delete this label?"
          onConfirm={confirm}
          onCancel={cancel}
          okText="Yes"
          cancelText="No"
          okButtonProps={{ loading: deletePending }}
        >
          <p>Delete</p>
        </Popconfirm>
      ),
      icon: <Trash2 size={16} strokeWidth={1.2} />,
      onClick: () => {
        setResponse(
          data?.data.labels[openDropdownIndex!] ?? {
            id: "",
            description: "",
            name: "",
          }
        );
        setDeleteState((prev) => ({ ...prev, [openDropdownIndex ?? 2]: true }));
      },
    },
  ];

  return (
    <div className="mx-[20px] pt-[15px] border-t ">
      <div className="flex justify-between items-center">
        <Text bold>Labels</Text>
        {status === "success" && data?.data.labels && (
          <Plus
            size={30}
            className="translate-x-3 hover:text-gray-800 hover:bg-gray-100 p-2 rounded-full cursor-pointer hover:scale-110"
            onClick={() => {
              setIsEditabel(false);
              setIsModalVisible(true);
              setResponse({ id: "", description: "", name: "" });
              setColor("");
            }}
          />
        )}
      </div>
      <div className="mt-1 max-h-[250px] scrollbar-visible overflow-scroll">
        {user?.inbox?.inbox_account_email === null ||
          (status === "pending" && (
            <div>
              <Loader2
                size={16}
                strokeWidth={1.2}
                className="text-gray-600 animate-spin mx-auto mt-2"
              />
            </div>
          ))}
        {status === "error" && <div>Error fetching labels</div>}
        {(status === "success" && data?.data.labels.length === 0 && (
          <p className="text-center text-sm text-gray-600 mt-2">
            No labels found
          </p>
        )) ||
          data?.data.labels.map((label, index) => (
            <div
              key={index}
              className={
                "flex items-center justify-between mt-5 hover:underline group"
              }
            >
              <div className="flex items-center">
                <div
                  className={`h-[15px] w-[15px] rounded-md border-2`}
                  style={{ borderColor: label.color }}
                />
                <Text className="ml-2 text-sm capitalize">
                  {label.name?.toLowerCase()}
                </Text>
              </div>
              <span
                className={
                  openDropdownIndex === index
                    ? "text-sm hidden text-gray-600 group-hover:hidden"
                    : "text-sm text-gray-600 group-hover:hidden"
                }
              >
                {9 + index}
              </span>
              <Dropdown
                menu={{ items }}
                onOpenChange={(open) => {
                  if (!open) {
                    setOpenDropdownIndex(null);
                    setDeleteState((prev) => ({ ...prev, [index]: false }));
                  }
                }}
                open={deleteState[index] || openDropdownIndex === index}
                key={index}
                placement="bottomLeft"
                arrow
              >
                <Button
                  size="small"
                  className={
                    openDropdownIndex === index
                      ? "bg-gray-100 translate-x-1 h-[18px]"
                      : "hidden group-hover:block h-[18px] translate-x-1 z-50"
                  }
                  icon={<MoreHorizontal size="16" strokeWidth={1.2} />}
                  onClick={(e) => {
                    e.preventDefault();
                    setOpenDropdownIndex(
                      openDropdownIndex === index ? null : index
                    );
                    setDeleteState((prev) => ({ ...prev, [index]: false }));
                  }}
                />
              </Dropdown>
            </div>
          ))}
      </div>
      <Modal
        title={isEditabel ? "Edit Label" : "New Label"}
        open={isModalVisible}
        okText={isEditabel ? "Update" : "Create"}
        okButtonProps={{
          loading: isEditabel ? updatePending : isPending,
        }}
        onOk={isEditabel ? handleUpdateLabel : handleCretaeLabel}
        onClose={() => setIsModalVisible(false)}
        onCancel={() => setIsModalVisible(false)}
        cancelButtonProps={{ className: "hidden" }}
      >
        <div>
          <label htmlFor="name" className="text-sm font-semibold">
            Please enter a new label name
          </label>
          <Input
            id="name"
            placeholder="Label name"
            className="capitalize"
            value={response.name?.toLowerCase()}
            onChange={(e) =>
              setResponse((prev) => {
                return { ...prev, name: e.target.value };
              })
            }
          />
        </div>
        <div className="mt-2">
          <label htmlFor="name" className="text-sm font-semibold">
            Please write the label description
          </label>
          {isEditabel ? (
            <p className="py-[5px] text-gray-700">{response.description}</p>
          ) : (
            <Input.TextArea
              id="name"
              placeholder="Description"
              className="max-h-[200px]"
              disabled={isEditabel}
              value={response.description}
              onChange={(e) => {
                setResponse((prev) => {
                  return { ...prev, description: e.target.value };
                });
              }}
            />
          )}
        </div>
        <div className="mt-[10px]">
          <label htmlFor="name" className="text-sm font-semibold">
            Please select a color:
          </label>
          <div className="flex items-center justify-start gap-[10px]">
            <ColorPickerComp color={color} setColor={setColor} />
            {color && (
              <div className="text-gray-600">
                Selected color:{" "}
                <span className={`font-mono text-['${color}']`}>{color}</span>
              </div>
            )}
          </div>
        </div>
      </Modal>
    </div>
  );
};

export default Labels;

type Presets = Required<ColorPickerProps>["presets"][number];

const genPresets = (presets = presetPalettes) =>
  Object.entries(presets).map<Presets>(([label, colors]) => ({
    label,
    colors,
  }));

interface ColorGenInput {
  setColor: React.Dispatch<React.SetStateAction<string>>;
  color: string;
}

const ColorPickerComp: React.FC<ColorGenInput> = ({ color, setColor }) => {
  const { token } = theme.useToken();
  const presets = genPresets({
    primary: generate(token.colorPrimary),
    red,
    green,
    cyan,
  });
  const customPanelRender: ColorPickerProps["panelRender"] = (
    _,
    { components: { Picker, Presets } }
  ) => (
    <Row justify="space-between" wrap={false}>
      <Col span={12}>
        <Presets />
      </Col>
      <Divider type="vertical" style={{ height: "auto" }} />
      <Col flex="auto">
        <Picker />
      </Col>
    </Row>
  );
  return (
    <ColorPicker
      defaultValue={token.colorPrimary}
      styles={{ popupOverlayInner: { width: 480 } }}
      presets={presets}
      panelRender={customPanelRender}
      value={color}
      arrow
      onChange={(value) => setColor(value.toHexString())}
    />
  );
};
