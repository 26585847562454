import { ArrowUpFromDot, FlipVertical } from "lucide-react";
import { Tooltip } from "antd";

const ScrollToActions = ({scrollToTop,scrollToThread}:{
    scrollToThread:()=>void,
    scrollToTop:()=>void
}) => {
  return (
    <div className="absolute bottom-0 left-3 flex flex-col gap-2 bg-gray-50 rounded-md px-[8px] py-[5px]">
      <Tooltip title="Scroll to top">
        <div className="group" onClick={scrollToTop}>
          <ArrowUpFromDot
            size={20}
            strokeWidth={1.5}
            className="text-gray-700 group-hover:scale-110 cursor-pointer"
          />
        </div>
      </Tooltip>
      <div className="bg-gray-300 w-full h-[1px]" />
      <Tooltip title="Find thread">
        <div className="group" onClick={scrollToThread}>
          <FlipVertical
            size={20}
            strokeWidth={1.5}
            className="text-gray-700 group-hover:scale-110 cursor-pointer"
          />
        </div>
      </Tooltip>
    </div>
  );
};

export default ScrollToActions;
