import React from "react";
import { Badge, Skeleton, Button, message, Tooltip } from "antd";
import LottiePlayer from "lottie-react";
import { useInView } from "react-intersection-observer";
import { Paperclip, Image, Video, Shapes } from "lucide-react";
import { useInfiniteQuery, useMutation } from "@tanstack/react-query";
import { useLocation, useParams, useNavigate } from "react-router-dom";
import Highlighter from "react-highlight-words";
import { Transition } from "@headlessui/react";

import { Avatar, AvatarFallback, AvatarImage } from "@/components/ui/avatar";

import ListHeader from "../components/ListHeader";

import { genrateInitials } from "@/helper/genrateInitials";
import { convertDateToTime } from "@/helper/timAgo";

import emptyIboxLottie from "@/assets/lottis/empty-inbox.json";
import GmailPlusLottie from "@/assets/lottis/gmail-pulse.json";

import { useUser } from "@/providers/userProvider";
import { useMyMail } from "@/providers/myMailContext";
import { useThread } from "@/providers/thredContext";

import { gmailApi, Label } from "../api/gmail";
import { Page } from "@/types/email.type";

import ScrollToActions from "./Scroller";

interface ListPros {
  setGlobalSearch: React.Dispatch<React.SetStateAction<string>>;
  labels: Label[] | undefined;
}

const List: React.FC<ListPros> = ({ setGlobalSearch, labels }) => {
  const { getMessageList, connectGmail } = gmailApi();
  const { user } = useUser();
  const { setPage, page } = useMyMail();
  const { setThread, thread } = useThread();
  const { pathname } = useLocation();
  const navigate = useNavigate();
  const { inbox_type } = useParams();

  const thread_id = pathname.split("/")[3];

  const [pageQuery, setPageQuery] = React.useState<string>("INBOX");
  const [searchTerm, setSearchTerm] = React.useState<string>("");
  const [isUnassigned,setIsUnassigned] = React.useState<boolean>(false)
  const [initialLoad, setInitialLoad] = React.useState(true);
  const [fetchNextPageCount, setFetchNextPageCount] = React.useState<number>(0);
  const [scrollToSelected, setScrollToSelected] =
    React.useState<boolean>(false);
  const [loadingNextPage, setLoadingNextPage] = React.useState<boolean>(false);
  const [hasScrolled, setHasScrolled] = React.useState(false);

  const { ref, inView } = useInView();
  const selectedThreadRef = React.useRef<HTMLDivElement>(null);

  const { mutate, isPending } = useMutation({
    mutationKey: ["connectGmail"],
    mutationFn: connectGmail,
    onSuccess: (data) => {
      return (window.location.href = data.data.url);
    },
  });

  const {
    data,
    isLoading,
    fetchNextPage,
    isFetchingNextPage,
    hasNextPage,
    status,
    refetch,
  } = useInfiniteQuery<Page, Error>({
    queryKey: ["getAllMails", pageQuery],
    queryFn: async ({ pageParam = null }) => {
      const response = await getMessageList(
        pageParam as string | null,
        pageQuery
      );
      return response.data as Page;
    },
    getNextPageParam: (lastPage) => {
      if (lastPage.messages.nextPageToken) {
        return lastPage.messages.nextPageToken ?? null;
      }
      return null;
    },
    initialPageParam: null,
    enabled: !!user?.inbox?.inbox_account_email,
    staleTime: 1000 * 60 * 5,
    refetchOnWindowFocus: false,
    refetchOnReconnect: false,
    retry: false,
  });

  React.useEffect(() => {
    if (pageQuery) {
      refetch();
    }
  }, [pageQuery, refetch]);

  React.useEffect(() => {
    if (
      user?.inbox?.inbox_account_email ||
      user?.inbox?.inbox_account_email !== null
    ) {
      if (inbox_type?.toLocaleLowerCase() === "all-mails") {
        setPageQuery("INBOX");
      } else if (inbox_type?.toLocaleLowerCase() === "sent") {
        setPageQuery("SENT");
      } else if (inbox_type?.toLocaleLowerCase() === "spams") {
        setPageQuery("SPAM");
      } else if(inbox_type?.toLocaleLowerCase() === "my-mails"){
        setPageQuery("ME")
      } else if(inbox_type?.toLocaleLowerCase() === "unassigned"){
        setIsUnassigned(true)
        setPageQuery("INBOX")
      }
    }
  }, [inbox_type, user?.inbox?.inbox_account_email]);

  React.useEffect(() => {
    if (inView && !isFetchingNextPage && hasNextPage) {
      fetchNextPage();
    }
  }, [inView, fetchNextPage, isFetchingNextPage, hasNextPage]);

  const newMessages = React.useMemo(() => {
    if (data?.pages) {
      const messages = data.pages.flatMap((page) => page.messages.messages);
      return messages;
    }
    return [];
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data?.pages, searchTerm]);


  const combinedMessages = React.useMemo(() => {
    if (newMessages.length) {
      const existingMessagesMap = new Map();
  
      newMessages.forEach((msg) => {
        const threadId = msg?.threadId;
        if (existingMessagesMap.has(threadId)) {
          const existingMessage = existingMessagesMap.get(threadId);
          if (new Date(msg.internalDate) > new Date(existingMessage.internalDate)) {
            existingMessagesMap.set(threadId, msg); 
          }
        } else {
          existingMessagesMap.set(threadId, msg); 
        }
      });
  
      return [
        ...existingMessagesMap.values()
      ]; 
    }
    return [];
  }, [newMessages]);
  

  React.useEffect(() => {
    if (data?.pages) {
      let filteredMessages = combinedMessages;
      const lastPage = data.pages[data.pages.length - 1];
      const nextPageToken = lastPage?.messages?.nextPageToken;

      const filterByLabel = (label: string) =>
        combinedMessages.filter((msg) => msg?.labelIds?.includes(label));
      
      const filterByAssignee = () => {
        return combinedMessages.filter((msg) => msg?.assignees?.length === 0);
      }

      const removeFilter = () => {
        setPage({
          messages: {
            messages: combinedMessages, // Reset to the original combinedMessages
            nextPageToken: page.messages.nextPageToken, // Retain the current nextPageToken
          },
          lastSyncDate: page.lastSyncDate,
        });
      };
      
    
      if (pageQuery === "SENT") {
        filteredMessages = filterByLabel("SENT");
      } else if (pageQuery === "SPAM") {
        filteredMessages = filterByLabel("SPAM");
      } else if (pageQuery === "TRASH") {
        filteredMessages = filterByLabel("TRASH");
      } else if (pageQuery === "ME") {
        filteredMessages = filterByLabel("ME");
      } else if (isUnassigned){
        filteredMessages = filterByAssignee()
      } else{
        removeFilter()
      }

      const hasNewMessages =
        filteredMessages.length !== page.messages.messages.length ||
        nextPageToken !== page.messages.nextPageToken;

      if (hasNewMessages) {
        setPage({
          messages: {
            messages: filteredMessages,
            nextPageToken: nextPageToken,
          },
          lastSyncDate: page.lastSyncDate || lastPage.lastSyncDate,
        });
      }
    }
  }, [
    data,
    combinedMessages,
    setPage,
    page.messages.messages.length,
    page.messages.nextPageToken,
    page.lastSyncDate,
    pageQuery,
    isUnassigned
  ]);

  const filteredMessages = React.useMemo(() => {
    if (!data || data.pages.length === 0) return [];
    if (searchTerm === "") {
      setGlobalSearch("");
      return combinedMessages;
    }
    const lowerCaseQuery = searchTerm.toLowerCase();
    const filters = lowerCaseQuery.split(" ");
    const hasFilter = filters.some((filter) => filter.includes(":"));
    if (hasFilter) {
      return combinedMessages.filter((mail) => {
        let matches = true;
        filters.forEach((filter) => {
          const [key, value] = filter.split(":");
          switch (key.toLowerCase()) {
            case "has":
              if (value === "attachment") {
                matches =
                  matches && (mail?.attachments?.length ?? 0) > 0
                    ? true
                    : false;
              }
              break;
            case "before":
              // eslint-disable-next-line no-case-declarations
              const dateValue = new Date(value);
              // eslint-disable-next-line no-case-declarations
              const mailDate = new Date(Number(mail.internalDate));
              matches = matches && mailDate < dateValue;
              break;
            case "global":
              setSearchTerm(value);
              setGlobalSearch(value);
              break;
            case "after":
              // eslint-disable-next-line no-case-declarations
              const afterDateValue = new Date(value);
              // eslint-disable-next-line no-case-declarations
              const afterMailDate = new Date(Number(mail.internalDate));
              matches = matches && afterMailDate > afterDateValue;
              break;
            default:
              break;
          }
        });
        return matches;
      });
    } else
      return combinedMessages.filter((mail) => {
        return mail.payload.headers.some((header: { name: string; value: string; }) => {
          return (
            (header.name === "From" &&
              header.value.toLowerCase().includes(lowerCaseQuery)) ||
            (header.name === "Subject" &&
              header.value.toLowerCase().includes(lowerCaseQuery)) ||
            mail.snippet.toLowerCase().includes(lowerCaseQuery)
          );
        });
      });
  }, [combinedMessages, searchTerm, setGlobalSearch, data]);

  const searchFilter = (search: string) => {
    setSearchTerm(search);
  };

  const generateRandomColor = () => {
    return `hsl(${Math.floor(Math.random() * 360)}, 50%, 90%)`;
  };

  React.useEffect(() => {
    if (thread_id) {
      setThread({
        thredId: thread_id,
      });
    }
  }, [thread_id, setThread]);

  const threadExistsInCurrentPage = page.messages.messages.some(
    (msg) => msg.threadId === thread?.thredId
  );

  React.useEffect(() => {
    if (
      thread?.thredId &&
      page.messages.messages.length > 0 &&
      selectedThreadRef.current &&
      scrollToSelected
    ) {
      selectedThreadRef.current.scrollIntoView({
        behavior: "smooth",
        block: "center",
      });
      setScrollToSelected(false);
    }
  }, [thread?.thredId, page.messages.messages, scrollToSelected]);

  const handleScroll = (event: React.UIEvent<HTMLDivElement>) => {
    const { scrollTop, clientHeight, scrollHeight } = event.currentTarget;
    if (scrollTop + clientHeight < scrollHeight) {
      setScrollToSelected(false);
    }
    if (scrollTop + clientHeight === scrollHeight) {
      setHasScrolled(true);
    } else {
      setHasScrolled(false);
    }
  };

  // write a funtion to find the thread in the current page
  const scrollToThread = () => {
    if (selectedThreadRef.current) {
      selectedThreadRef.current.scrollIntoView({
        behavior: "smooth",
        block: "center",
      });
    }
  };

  // write a fuction to take the scroll to top
  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };

  React.useEffect(() => {
    if (initialLoad) {
      setScrollToSelected(true);
      setInitialLoad(false);
    }
  }, [initialLoad]);

  React.useEffect(() => {
    const loadNextPageIfNeeded = async () => {
      if (
        !threadExistsInCurrentPage &&
        hasNextPage &&
        fetchNextPageCount < 2 &&
        thread_id
      ) {
        try {
          setLoadingNextPage(true);
          await fetchNextPage();
          setFetchNextPageCount((prev) => prev + 1);
        } catch (error) {
          message.error("Failed to fetch next page. Please try again.");
        } finally {
          setLoadingNextPage(false);
        }
      }
    };
    loadNextPageIfNeeded();
  }, [
    threadExistsInCurrentPage,
    fetchNextPage,
    hasNextPage,
    fetchNextPageCount,
    thread_id,
  ]);

  React.useEffect(() => {
    if (thread) {
      setPage((prev) => {
        const updatedMessages = prev.messages.messages.map((mail) => {
          if (
            mail.threadId === thread.thredId &&
            mail.labelIds.includes("UNREAD")
          ) {
            return {
              ...mail,
              labelIds: mail.labelIds.filter((label) => label !== "UNREAD"),
            };
          }
          return mail;
        });

        const hasChanges = updatedMessages.some(
          (mail, index) =>
            JSON.stringify(mail) !==
            JSON.stringify(prev.messages.messages[index])
        );

        if (hasChanges) {
          return {
            ...prev,
            messages: {
              ...prev.messages,
              messages: updatedMessages,
            },
          };
        }
        return prev;
      });
    }
  }, [thread, setPage, page.messages.messages]);

  return (
    <div className="min-w-[500px] border-r">
      <div>
        <ListHeader
          loading={isLoading}
          count={filteredMessages?.length || 0}
          lastSyncDate={
            (page?.lastSyncDate as Date) || data?.pages[0].lastSyncDate
          }
          searchFilter={searchFilter}
          loadingNextPage={loadingNextPage}
        />
      </div>
      <div className="flex items-center min-w-[500px]" onScroll={handleScroll}>
        <div className="h-[calc(100vh-100px)] overflow-scroll py-[5px] w-full scrollbar-visible overflow-x-hidden relative">
          {!user?.inbox?.inbox_account_email && (
            <div className="flex items-center justify-center gap-2 flex-col h-full">
              <LottiePlayer
                animationData={GmailPlusLottie}
                className="mx-auto w-full h-[300px]"
              />
              <p className="text-center text-gray-600">
                {user?.is_main_account &&
                user?.inbox?.inbox_account_email === null ? (
                  <>
                    You're one step away from effortlessly organizing <br />
                    all your communications. <br />
                    <Button
                      type="primary"
                      className="mt-4"
                      onClick={() => {
                        mutate();
                      }}
                      loading={isPending}
                    >
                      Connect Gmail
                    </Button>
                  </>
                ) : user?.inbox?.inbox_account_email === null ? (
                  <>
                    Your organization does not have any active mailboxes! <br />{" "}
                    Connect your support mailbox to wingg to get started.
                  </>
                ) : (
                  <>
                    You're one step away from effortlessly organizing <br />
                    all your communications. <br />
                    <Button
                      type="primary"
                      className="mt-4"
                      onClick={() => {
                        mutate();
                      }}
                      loading={isPending}
                    >
                      Connect Gmail
                    </Button>
                  </>
                )}
              </p>
            </div>
          )}
          {status === "pending" && isLoading && (
            <div className="p-4 mt-[-15px]">
              {Array.from({ length: 6 }).map((_, index) => (
                <Skeleton avatar key={index} active title className="p-4" />
              ))}
            </div>
          )}
          {status === "success" && (
            <>
              {data.pages.length === 0 ? (
                <div className="flex flex-col items-center justify-center h-full gap-0">
                  <LottiePlayer
                    animationData={emptyIboxLottie}
                    loop={false}
                    autoplay
                    className="mx-auto w-full pl-10 h-[400px]"
                  />
                  <p className="text-center">Your inbox is empty</p>
                </div>
              ) : null}
            </>
          )}

          {status === "success" &&
          filteredMessages?.length === 0 &&
          !isLoading &&
          !isFetchingNextPage ? (
            <div>
              <div className="flex flex-col items-center justify-center h-full gap-0">
                <LottiePlayer
                  animationData={emptyIboxLottie}
                  loop={false}
                  autoplay
                  className="mx-auto w-full pl-10 h-[400px]"
                />
                <p className="text-center">No results found</p>
              </div>
            </div>
          ) : (
            filteredMessages
              ?.sort((a, b) => Number(b.internalDate) - Number(a.internalDate))
              .map((mail, index) => (
                <div
                  key={mail?.threadId}
                  ref={
                    mail?.threadId === thread?.thredId
                      ? selectedThreadRef
                      : null
                  }
                  className={
                    mail?.threadId === thread?.thredId
                      ? "px-[10px] py-2 w-[500px] flex items-center gap-[10px] hover:shadow-lg rounded-sm border border-dashed border-[#fb6c3c]/60 shadow-lg"
                      : "px-[10px] py-2 w-[500px] flex items-center gap-[10px] hover:shadow-lg border-b hover:bg-[#fad3c6]/10"
                  }
                  onClick={() => {
                    navigate(`/inbox/${inbox_type}/${mail?.threadId}`);
                    setThread({
                      thredId: mail?.threadId,
                    });
                    setScrollToSelected(true);
                  }}
                >
                  <div className="flex gap-[10px]">
                    <Avatar className="h-[50px] w-[50px] text-[20px] drop-shadow-lg">
                      <AvatarImage
                        src={genrateInitials(
                          mail?.payload.headers
                            .find(
                              (header: { name: string; value: string }) =>
                                header.name === "From"
                            )
                            ?.value.split("<")[0]
                            ?.replace(/"/g, "")
                            ?.replace(/'/g, "")
                            ?.charAt(0) as string
                        ) as string}
                      />
                      <AvatarFallback>
                        <span>
                          {mail?.payload.headers &&
                            mail.payload?.headers
                              ?.find(
                                (header: { name: string; value: string }) =>
                                  header.name === "From"
                              )
                              ?.value.split("<")[0]
                              ?.replace(/"/g, "")
                              ?.replace(/'/g, "")
                              .charAt(0)}
                        </span>
                      </AvatarFallback>
                    </Avatar>
                  </div>
                  <div className="flex flex-col gap-[3px]">
                    <div className="flex items-center justify-between ">
                      <h4 className="text-[13px] font-semibold flex items-center gap-1">
                        {mail?.labelIds?.find(
                          (label: string) => label === "UNREAD"
                        ) && (
                          <Badge
                            color="red"
                            status="processing"
                            classNames={{ indicator: "mr-1" }}
                          />
                        )}
                        <Highlighter
                          highlightClassName="bg-orange-300 py-[1px] rounded-md px-[3px]"
                          searchWords={[searchTerm]}
                          autoEscape={true}
                          textToHighlight={
                            mail?.payload?.headers
                              ?.find(
                                (header: { name: string; value: string }) =>
                                  header.name === "From"
                              )
                              ?.value?.split("<")[0]
                              ?.replace(/"/g, "") || ""
                          }
                        />
                        {mail?.threadCount > 1 && (
                          <Badge
                            status="success"
                            color="#a1a1a1"
                            classNames={{
                              root: "!rounded-md",
                              indicator: "!rounded-md",
                            }}
                            size="small"
                            rootClassName="text-sm"
                            count={mail.threadCount}
                          />
                        )}
                      </h4>
                      <span className="text-sm text-gray-500">
                        {convertDateToTime(mail?.internalDate)}
                      </span>
                    </div>
                    <p className="text-sm text-gray-700">
                      <Highlighter
                        highlightClassName="bg-orange-300 py-[1px] rounded-md px-[3px]"
                        searchWords={[searchTerm]}
                        autoEscape={true}
                        textToHighlight={
                          mail?.payload.headers.find(
                            (header: { name: string; value: string }) =>
                              header.name === "Subject"
                          )?.value || ""
                        }
                      />
                    </p>
                    <p className="text-sm text-gray-500 text-ellipsis whitespace-nowrap w-[414px] overflow-hidden">
                      <Highlighter
                        highlightClassName="bg-orange-300 py-[1px] rounded-md px-[3px]"
                        searchWords={[searchTerm]}
                        autoEscape={true}
                        textToHighlight={mail?.snippet || ""}
                      />
                    </p>
                    <div className="flex items-center justify-between mt-[5px]">
                      {mail?.attachments?.length &&
                      mail?.attachments.length > 0 ? (
                        <div className="flex gap-2">
                          {mail?.attachments.filter(
                            (att: { type: string; }) => att.type === "pdf" || att.type === "docx"
                          ).length > 0 && (
                            <div className="flex gap-1 items-center bg-gray-100 px-2 py-1 rounded-md">
                              <Paperclip size={16} />
                              <span className="text-sm text-gray-500">
                                {
                                  mail.attachments.filter(
                                    (att: { type: string; }) =>
                                      att.type === "pdf" || att.type === "docx"
                                  ).length
                                }
                              </span>
                            </div>
                          )}
                          {mail?.attachments.filter(
                            (att: { type: string; }) => att.type === "image"
                          ).length > 0 && (
                            <div className="flex gap-1 items-center bg-gray-100 px-2 py-1 rounded-md">
                              <Image size={16} />

                              <span className="text-sm text-gray-500">
                                {
                                  mail?.attachments.filter(
                                    (att: { type: string; }) =>
                                      att.type === "image" ||
                                      att.type === "docx"
                                  ).length
                                }
                              </span>
                            </div>
                          )}
                          {mail?.attachments.filter(
                            (att: { type: string; }) => att.type === "video"
                          ).length > 0 && (
                            <div className="flex gap-1 items-center bg-gray-100 px-2 py-1 rounded-md">
                              <Video size={16} />
                              <span className="text-sm text-gray-500">
                                {
                                  mail?.attachments.filter(
                                    (att: { type: string; }) => att.type === "video"
                                  ).length
                                }
                              </span>
                            </div>
                          )}
                          {mail?.attachments.filter(
                            (att: { type: string; }) => att.type === "other"
                          ).length > 0 && (
                            <div className="flex gap-1 items-center bg-gray-100 px-2 py-1 rounded-md">
                              <Shapes size={16} />
                              <span className="text-sm text-gray-500">
                                {
                                  mail.attachments.filter(
                                    (att: { type: string; }) => att.type === "other"
                                  ).length
                                }
                              </span>
                            </div>
                          )}
                        </div>
                      ) : (
                        <div />
                      )}
                      <div className="flex items-center gap-2">
                        <span
                          key={mail?.id}
                          className={`text-sm flex items-center gap-[5px] text-gray-700 capitalize px-[10px] py-1 rounded-md `}
                          style={{
                            backgroundColor:
                              labels?.find(
                                (label) =>
                                  label.name?.toUpperCase() ===
                                  mail.label?.toUpperCase()
                              )?.secondaray_color ||
                              labels?.find(
                                (label) =>
                                  label.name?.toUpperCase() ===
                                  "unlabelled".toUpperCase()
                              )?.secondaray_color,
                          }}
                        >
                          {
                            <div
                              key={mail?.id + index}
                              className={
                                "h-[12px] w-[12px] rounded-md border-2"
                              }
                              style={{
                                borderColor:
                                  labels?.find(
                                    (label) =>
                                      label.name?.toUpperCase() ===
                                      mail.label?.toUpperCase()
                                  )?.color ||
                                  labels?.find(
                                    (label) =>
                                      label.name?.toUpperCase() ===
                                      "unlabelled".toUpperCase()
                                  )?.color,
                              }}
                            />
                          }
                          {mail.label?.toLowerCase() || "unlabelled"}
                        </span>
                        {mail.assignees.length > 0 && (
                          <div className="flex gap-1">
                            {mail.assignees.map((assignee:{name:string,email:string,profile_image:string}) => (
                              <Tooltip
                                title={
                                  <div>
                                    <p>{assignee.name}</p>
                                    <p className="text-sm">{assignee.email}</p>
                                  </div>
                                }
                              >
                                <Avatar
                                  key={assignee.email}
                                  className="h-[25px] w-[25px] text-[10px] drop-shadow-lg rounded-lg"
                                >
                                  <AvatarImage src={assignee.profile_image} />
                                  <AvatarFallback
                                    style={{
                                      background: generateRandomColor(),
                                    }}
                                  >
                                    <span>
                                      {genrateInitials(assignee.name)}
                                    </span>
                                  </AvatarFallback>
                                </Avatar>
                              </Tooltip>
                            ))}
                          </div>
                        )}
                      </div>
                    </div>
                  </div>
                </div>
              ))
          )}

          <Transition
            show={hasScrolled}
            enter="transform transition ease-in-out duration-300"
            enterFrom="translate-y-full"
            enterTo="translate-y-0"
            leave="transform transition ease-in-out duration-300"
            leaveFrom="translate-y-0"
            leaveTo="translate-y-full"
          >
            <ScrollToActions
              scrollToThread={scrollToThread}
              scrollToTop={scrollToTop}
            />
          </Transition>
          {status === "success" && (
            <div ref={ref}>
              {isFetchingNextPage && (
                <div className="w-full flex items-center justify-center px-[40px] py-[10px]">
                  <Skeleton avatar active title />
                </div>
              )}
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default List;
