import { Button, Input } from "antd";
import { useParams } from "react-router-dom";
import { RefreshCcw, Loader2 } from "lucide-react";

import { timeAgo } from "@/helper/timAgo";

import useRefetchLatestEmail from "../hooks/useRefetchLatestEmail";

const ListHeader = ({
  count,
  lastSyncDate,
  loading,
  searchFilter,
  loadingNextPage
}: {
  loading: boolean;
  count: number;
  lastSyncDate: Date;
  searchFilter: (value: string) => void;
  loadingNextPage: boolean;
}) => {
  const { isSyncMail, refetchLatestAllEmail } = useRefetchLatestEmail();
  const params = useParams();
  return (
    <div className="px-[10px] border-b pb-[10px] bg-white max-w-[500px]">
      <div className="py-[10px] bg-white max-w-full flex items-center justify-between">
        <div className="flex gap-2 items-center">
          <h4 className="text-[15px] font-semibold capitalize">
            {params?.inbox_type?.split("-").join(" ")}
          </h4>
          <span className="text-sm text-gray-500">{count} messages</span>
        </div>
        <div className="flex gap-2 items-center">
          {lastSyncDate === null || lastSyncDate === undefined ? null : (
            <>
              <p className="text-[10px] text-gray-400 uppercase flex gap-2 items-center justify-center">
                last sync
                {loading ? (
                  <Loader2 size="12" className="text-gray-500 animate-spin" />
                ) : (
                  <span className="lowercase font-semibold text-gray-600">
                    {timeAgo(lastSyncDate)}
                  </span>
                )}
              </p>

              <Button
                onClick={refetchLatestAllEmail}
                type="text"
                className="hover:!bg-[#ffc4af5f]"
                icon={<RefreshCcw size="18" className="text-gray-500" />}
                loading={isSyncMail}
              />
            </>
          )}
        </div>
      </div>
      <Input.Search
        onChange={(e) => searchFilter(e.target.value)}
        placeholder="Search"
        loading={loadingNextPage}
        className="border-none mt-[-6px]"
        classNames={{
          input:
            "hover:!border-[#fa6e3c] active:!border-[#fa6e3c] focus-within:!border-[#fa6e3c] focus:!border-[#fa6e3c] focus:!ring-[#fa6e3c] focus:!ring-opacity-50",
        }}
      />
      <style>{`
      .ant-input-group-addon:hover {
      color: #fa6e3c; /* Change parent color */
      }
    
      .ant-input-group-addon:hover .anticon-search {
        color: #fa6e3c; /* Change child color on parent hover */
      }
    `}</style>
    </div>
  );
};

export default ListHeader;
